<template>
    <div>
        <div class="alert alert-danger | text-center mb-6" v-if="error">
            {{ $t(`auth.newPassword.errors.${error}`) }}
        </div>

        <form @submit.prevent="handleSubmit">
            <p class="m-0 text-center pb-6" v-html="$t('auth.newPassword.instructions')" />

            <activix-input
                class="mb-4"
                type="password"
                :placeholder="$t('auth.password')"
                v-model="password"
            >
                <icon class="text-grey-600" name="regular/lock-1" slot="icon" />
            </activix-input>

            <activix-input
                class="mb-6"
                type="password"
                :placeholder="$t('auth.passwordConfirmation')"
                v-model="passwordConfirmation"
            >
                <icon class="text-grey-600" name="regular/lock-1" slot="icon" />
            </activix-input>

            <activix-button
                class="w-full"
                submit
                type="primary"
                :loading="$wait.is('sending.newPassword')"
                :disabled="!isValid"
            >
                {{ $t('auth.newPassword.changePassword') }}
            </activix-button>
        </form>
    </div>
</template>

<script>
    export default {
        props: {
            email: {
                type: String,
                required: true,
            },
            token: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                error: null,
                password: '',
                passwordConfirmation: '',
            };
        },

        computed: {
            isValid() {
                return (
                    !!this.password &&
                    !!this.passwordConfirmation &&
                    this.password.length >= 8 &&
                    this.passwordConfirmation.length >= 8
                );
            },
        },

        methods: {
            async handleSubmit() {
                this.error = null;

                if (this.password != this.passwordConfirmation) {
                    this.error = 'passwordDoNotMatch';
                    return;
                }

                this.$wait.start('sending.newPassword');

                try {
                    await this.$axios.post('password/reset', {
                        email: this.email,
                        password: this.password,
                        password_confirmation: this.passwordConfirmation,
                        token: this.token,
                    });

                    this.$wait.end('sending.newPassword');

                    this.$message.success(this.$t('auth.newPassword.alerts.success'));

                    this.$router.push({ name: 'auth.login' });
                } catch (error) {
                    if (error.response.data.email) {
                        this.error = 'invalidToken';
                    } else {
                        this.error = 'unknownError';
                    }

                    this.$wait.end('sending.newPassword');
                }
            },
        },
    };
</script>
