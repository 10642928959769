<template>
    <div>
        <h3 class="text-4xl mt-0 mb-3">
            {{ $t('auth.error') }}
        </h3>
        <p class="text-lg mt-0 mb-6">
            {{ errorMessage }}
        </p>
        <activix-button type="primary" :loading="loading" @click="redirectToLogin">
            {{ $t('auth.retry') }}
        </activix-button>
    </div>
</template>

<script>
    export default {
        props: {
            error: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                loading: false,
            };
        },

        computed: {
            errorMessage() {
                const key = `auth.errors.${this.error}`;

                return this.$te(key) ? this.$t(key) : this.$t('auth.errors.unknown');
            },
        },

        methods: {
            redirectToLogin() {
                this.loading = true;
                this.$auth.logout();
            },
        },
    };
</script>
